<template functional>
  <v-tooltip top v-if="props.pClass.iconSource">
    <template v-slot:activator="{ on, attrs }">
      <img
        v-bind="attrs"
        v-on="on"
        aria-hidden
        class="mr-3 mb-1"
        :src="props.pClass.iconSource"
        :width="props.width"
        :height="props.height"
      />
    </template>
    <span>{{ props.pClass.name }}</span>
  </v-tooltip>
</template>
<style scoped lang="scss">
div {
  width: fit-content;
}
</style>
<script>
export default {
  name: "ProductClass",
  props: {
    pClass: { type: Object },
    height: { type: Number, default: 22 },
    width: { type: Number, default: 22 }
  }
};
</script>
