var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"product-card ingredient-card elevation-0",class:[_vm.promoClass],attrs:{"to":_vm.product.existsInShop
      ? { name: 'Product', params: { slug: _vm.product.slug } }
      : null}},[_c('div',{staticClass:"product w-100"},[_c('div',{staticClass:"product-classes-wrapper w-100 d-flex justify-end"},_vm._l((_vm.productClasses),function(pClass){return _c('ProductClass',{key:pClass.productClassid,attrs:{"pClass":pClass}})}),1),_c('div',{staticClass:"d-flex body flex-column w-100"},[_c('v-row',{staticClass:"ingredient-container",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"4","sm":"2","md":"2"}},[(_vm.product.existsInShop)?_c('img',{staticClass:"product-img align-self-center",attrs:{"eager":"","src":_vm.product.mediaURL,"max-width":_vm.$vuetify.breakpoint.xs ? 110 : 146,"max-height":_vm.$vuetify.breakpoint.xs ? 110 : 146,"alt":'Immagine' + _vm.product.name,"title":_vm.product.name +
                ' (' +
                _vm.product.codInt +
                '-' +
                _vm.product.codVar +
                ')'}}):_vm._e()]),_c('v-col',{attrs:{"cols":"8","sm":"6","md":"6"}},[_c('div',{staticClass:"description"},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.product.wp_amount)+_vm._s(_vm.product.wp_unit)+" di "+_vm._s(_vm.product.wp_ingredient))]),_c('span',{staticClass:"brand"},[_vm._v(_vm._s(_vm.product.name))])])]),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('div',{staticClass:"actions"},[(_vm.product.available > 0)?[_c('div',{staticClass:"qty-wrap",class:{ 'not-empty': _vm.quantity > 0 },attrs:{"transition":"fab-transition"}},[_c('a',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"minus",attrs:{"role":"button","data-test":"sub-qty-btn"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.minus.apply(null, arguments)},"mousedown":function($event){$event.stopPropagation();}}},[_c('v-icon',[_vm._v("$minus")])],1),_c('div',{staticClass:"val-cnt"},[_c('span',{staticClass:"val",attrs:{"data-test":"cart-item-product-val"}},[_vm._v(_vm._s(_vm.quantity)+" "+_vm._s(_vm.unit))]),_c('span',{staticClass:"small"},[_vm._v(_vm._s(_vm.quantityPerUnit))])]),_c('a',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"plus",attrs:{"role":"button","data-test":"add-to-cart"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.plus.apply(null, arguments)},"mousedown":function($event){$event.stopPropagation();}}},[_c('v-icon',[_vm._v("$plus")])],1)]),_c('v-btn',{staticClass:"add-to-cart",attrs:{"color":"secondary","elevation":"0"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.addToCart(_vm.quantity)}}},[_c('v-icon',[_vm._v("$cart")])],1)]:_c('div',{staticClass:"not-available"},[_vm._v(" Il prodotto non è disponibile ")])],2)])],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }