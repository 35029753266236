<template>
  <v-container fluid class="recipe-detail-container" v-if="pageData">
    <Breadcrumb v-if="$vuetify.breakpoint.smAndUp" :items="breadcrumb" />
    <h1 class="ma-4">{{ pageData.title.rendered }}</h1>

    <v-row>
      <v-col cols="12" md="9">
        <v-card class="recipe-card mt-2" flat data-test="recipe-card">
          <v-img
            :class="$vuetify.breakpoint.smAndDown && 'mx-4'"
            :src="pageData.featured_image_url"
          ></v-img>
          <v-row>
            <v-col cols="12" lg="6">
              <v-card-text>
                <span
                  class="description"
                  v-html="pageData.content.unrendered"
                ></span
                ><br />

                <div class="characteristics mt-3">
                  <span class="cook-time mr-4" v-if="pageData.recipe_cook_time">
                    <!-- <v-icon small class="mr-2">$clock</v-icon> -->
                    {{ pageData.recipe_cook_time }}</span
                  >
                  <span class="difficulty mt-1 mr-4" v-if="rating">
                    <!-- <v-icon small class="mr-2">$chef</v-icon> -->
                    {{ rating }}</span
                  >
                  <span class="meal-course mt-2 px-2" v-if="pageData.piatti"
                    ><strong>{{ pageData.piatti }}</strong></span
                  >
                </div>
              </v-card-text>
            </v-col>
            <v-col cols="12" lg="6">
              <v-card-title>
                <h2>Ingredienti</h2>
              </v-card-title>
              <v-list v-if="pageData.recipe_ingredients">
                <v-list-item
                  v-for="(ingredient, index) in ingredients"
                  :key="ingredient.ingredient_id"
                  class="ingrediets-list"
                >
                  <v-list-item-content class="d-flex flex-column">
                    <IngredientCard v-bind:product="ingredient" />
                    <v-divider
                      v-if="index < ingredients.length - 1"
                      :key="index"
                    ></v-divider>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-card-text>
                <v-row>
                  <v-col cols="6" xs="12">
                    <v-btn
                      outlined
                      color="secondary"
                      class="recipe-btn bg-white"
                      data-test="recipe-add-all-to-list"
                      large
                      depressed
                      @click="addAllToFavorites"
                    >
                      {{ $t("recipe.detail.addAllProductToListBtn") }}
                    </v-btn>
                  </v-col>
                  <v-col cols="6" xs="12">
                    <v-btn
                      color="primary"
                      class="recipe-btn"
                      data-test="recipe-add-all-to-cart"
                      large
                      depressed
                      @click="addAllToCart"
                    >
                      {{ $t("recipe.detail.addAllProductBtn") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
        <v-card flat class="description-card mb-5" v-if="description">
          <v-card-text>{{ description }} </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="0" md="3" v-if="$vuetify.breakpoint.mdAndUp">
        <RecipeSearch changePage="true" />
        <SuggestedRecipeNews showNews="false" showRecipe="true" />
      </v-col>
    </v-row>
  </v-container>
</template>
<style lang="scss">
.recipe-detail-container {
  background-color: #351313;
  h1,
  .suggestedRecipe h3,
  .v-breadcrumbs * {
    color: #ffffff !important;
  }
  .recipe-btn {
    width: 100%;
  }
  .recipe-card.v-card {
    display: flex;
    flex-direction: column;
    // @media #{map-get($display-breakpoints, 'xs-only')} {
    //   flex-direction: column;
    // }
    .v-card__text {
      font-size: 14px;
      white-space: break-spaces;
      .characteristics {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        .meal-course {
          color: white;
          background-color: var(--v-secondary-base);
          border-radius: 10px;
          width: max-content;
        }
      }
    }
    .v-image {
      max-height: 300px;
    }
  }
  img.size-full {
    width: 100%;
  }
  .v-expansion-panel-header {
    padding: 16px 28px;
    font-size: 16px;
  }
  .v-expansion-panel-content__wrap {
    padding: 0 28px 16px;
  }
  .ingrediets-list .v-divider {
    width: 100%;
    .product.ingrediet-card {
      display: none;
    }
  }
  // h2 {
  //   margin-top: 20px;
  // }
  @media #{map-get($display-breakpoints, 'xs-only')} {
  }
}
</style>
<script>
import RecipeSearch from "@/components/wordpress/RecipeSearch.vue";
import SuggestedRecipeNews from "@/components/wordpress/SuggestedRecipeNews.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import IngredientCard from "@/components/product/IngredientCard.vue";
import ListSelector from "@/components/lists/ListSelector.vue";

import clickHandler from "~/mixins/clickHandler";
import categoryMixins from "~/mixins/category";
import productMixin from "~/mixins/product";
import login from "~/mixins/login";

import CMSService from "~/service/cmService";
import ProductService from "~/service/productService";
import CartService from "~/service/cartService";
import ListService from "~/service/listService";

import get from "lodash/get";

import { mapActions } from "vuex";

export default {
  name: "RecipeDetail",
  mixins: [clickHandler, categoryMixins, productMixin, login],
  components: { RecipeSearch, SuggestedRecipeNews, Breadcrumb, IngredientCard },
  data() {
    return {
      content: null,
      pageData: null,
      products: null,
      ingredients: [],
      items: []
    };
  },
  // props: {
  //   recipeName: {
  //     type: String,
  //     default: null
  //   }
  // },
  mounted() {
    this.fetchRecipe(this.$route.params.recipeName);
    // this.fetchPageData(this.$route.params.recipeName);
  },
  computed: {
    rating() {
      return get(this.pageData, "rating");
    },
    description() {
      return get(this.pageData, "description[0]");
    }
  },
  methods: {
    ...mapActions({
      addProduct: "cart/addProduct"
    }),
    async fetchRecipe(recipeName) {
      const res = await CMSService.getCustomPostByFilters("recipe", [
        {
          filterName: "filter[recipe]",
          filterValue: recipeName
        }
      ]);

      if (res && res.data && res.data.length) {
        this.content = res.data;
        this.fetchPageData(this.content[0].id);
      }
    },
    async fetchPageData(recipeId) {
      const result = await CMSService.getCustomPostByFilters(
        "recipe/" + recipeId
      );
      if (result) {
        this.pageData = result.data;
        const wpIngredients = this.pageData.recipe_ingredients[0];
        const ids = wpIngredients.map(product => Number(product.notes));
        const ingredients = await ProductService.getProductsByIds(ids);
        this.products = ingredients.products;
        console.log("PRODUCTS", this.products);
        for (const ingredient of wpIngredients) {
          let objIngr = ingredients.products.find(
            ({ productId }) => productId == ingredient.notes
          );
          if (objIngr) {
            objIngr.existsInShop = true;
            const quantity = CartService.plus(objIngr, 0, false);
            this.items.push({ product: objIngr, quantity });
          } else {
            objIngr = {};
            objIngr.existsInShop = false;
          }

          objIngr.wp_amount = ingredient.amount;
          objIngr.wp_unit = ingredient.unit;
          objIngr.wp_ingredient = ingredient.ingredient;
          this.ingredients.push(objIngr);
        }
      }
    },
    async addAllToCart() {
      // let qty;
      if (await this.needLogin("addtocart")) {
        if (await this.needAddress()) {
          if (await this.needTimeslot()) {
            for (const product of this.products) {
              // if (product.productInfos.TIPOLOGIA === "Sfuso") {
              //   qty = product.productInfos.STEP_SELLING;
              // } else {
              //   qty =
              //     product.productInfos.QTY_MULT || product.productInfos.MINACQ;
              // }
              const quantity = CartService.plus(product, 0, false);
              await this.addProduct({ product, quantity });
            }
          }
        }
      }
    },
    async addAllToFavorites() {
      if (await this.needLogin("addtocart")) {
        let vm = this;
        let res = await vm.$dialog.show(ListSelector, {
          waitForResult: true,
          fullscreen: vm.$vuetify.breakpoint.smAndDown,
          width: 300
        });
        // let newQuantity = CartService.plus(vm.product, vm.quantity);
        if (res) {
          await ListService.addProductsToList(res.listId, this.items);
        }
      }
    }
  },
  watch: {
    "$route.params.recipeName"() {
      this.fetchRecipe(this.$route.params.recipeName);
    }
  }
};
</script>
