<template>
  <v-card
    class="product-card ingredient-card elevation-0"
    :class="[promoClass]"
    :to="
      product.existsInShop
        ? { name: 'Product', params: { slug: product.slug } }
        : null
    "
  >
    <div class="product w-100">
      <!-- <div class="header d-flex justify-space-between align-center">
        <ProductPromo
          :warehousePromo="product.warehousePromo"
          v-if="product.warehousePromo"
        />
        <div v-else-if="product.isNew" class="new-product">NOVIT&Agrave;</div>
        <v-spacer />
        <div class="icons-box">
          <ProductAcqLimit :product="product" />
        </div>
      </div> -->
      <div class="product-classes-wrapper w-100 d-flex justify-end">
        <ProductClass
          :pClass="pClass"
          v-for="pClass in productClasses"
          :key="pClass.productClassid"
        />
      </div>
      <div class="d-flex body flex-column w-100">
        <v-row no-gutters class="ingredient-container">
          <v-col cols="4" sm="2" md="2">
            <img
              v-if="product.existsInShop"
              eager
              :src="product.mediaURL"
              class="product-img align-self-center"
              :max-width="$vuetify.breakpoint.xs ? 110 : 146"
              :max-height="$vuetify.breakpoint.xs ? 110 : 146"
              :alt="'Immagine' + product.name"
              :title="
                product.name +
                  ' (' +
                  product.codInt +
                  '-' +
                  product.codVar +
                  ')'
              "
            />
          </v-col>
          <v-col cols="8" sm="6" md="6">
            <div class="description">
              <span class="name"
                >{{ product.wp_amount }}{{ product.wp_unit }} di
                {{ product.wp_ingredient }}</span
              >
              <span class="brand">{{ product.name }}</span>
            </div>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <div class="actions">
              <!-- <ProductPrice :product="product" /> -->
              <template v-if="product.available > 0">
                <div
                  class="qty-wrap"
                  :class="{ 'not-empty': quantity > 0 }"
                  transition="fab-transition"
                >
                  <a
                    role="button"
                    class="minus"
                    v-ripple
                    @click.stop.prevent="minus"
                    @mousedown.stop
                    data-test="sub-qty-btn"
                  >
                    <!-- v-ripple -->
                    <v-icon>$minus</v-icon>
                  </a>
                  <div class="val-cnt">
                    <span class="val" data-test="cart-item-product-val"
                      >{{ quantity }} {{ unit }}</span
                    >
                    <span class="small">{{ quantityPerUnit }}</span>
                  </div>
                  <a
                    role="button"
                    class="plus"
                    v-ripple
                    @click.stop.prevent="plus"
                    @mousedown.stop
                    data-test="add-to-cart"
                  >
                    <!-- v-ripple -->
                    <v-icon>$plus</v-icon>
                  </a>
                </div>
                <v-btn
                  color="secondary"
                  class="add-to-cart"
                  elevation="0"
                  @click.stop.prevent="addToCart(quantity)"
                >
                  <v-icon>$cart</v-icon></v-btn
                >
              </template>

              <div v-else class="not-available">
                Il prodotto non è disponibile
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-card>
</template>
<style lang="scss" scoped>
.header {
  height: 45px;
  width: 100%;
  .icons-box {
    display: flex;
    margin-left: 3px;
  }
}
.not-available {
  color: $promo;
  font-size: 12px;
  white-space: normal;
  height: 40px;
  display: flex;
  align-items: flex-end;
  text-align: right;
}
.product-card {
  width: 100%;
}
.ingredient-container {
  height: 50px;
  margin-bottom: 16px !important;
}
.name {
  font-size: 14px !important;
}
.cur-price {
  font-size: 18px !important;
}
.product-img {
  max-width: 64px;
}
.actions {
  justify-content: flex-end !important;
}
@media #{map-get($display-breakpoints, 'xs-only')} {
  .ingredient-container {
    height: auto;
    margin-bottom: 16px !important;
  }
  .product-img {
    max-width: 42px;
  }
  .name {
    font-size: 11px !important;
  }
  .qty-wrap div {
    font-size: 11px !important;
    padding: 0 4px !important;
  }
  .qty-wrap i {
    font-size: 18px !important;
  }
}
</style>
<script>
//TODO sistemare la visualizzazione della card su iphone SE dimensione della description
import ProductClass from "./ProductClass";
// import ProductAcqLimit from "./ProductAcqLimit";
// import ProductPromo from "./ProductPromo";
// import ProductPrice from "./ProductPrice";
import { mapState, mapGetters } from "vuex";
import productMixin from "~/mixins/product";

export default {
  name: "ProductCard",
  props: ["product"],
  // components: { ProductClass, ProductAcqLimit, ProductPromo, ProductPrice },
  components: { ProductClass },
  mixins: [productMixin],
  computed: {
    ...mapGetters({
      getItemByProductId: "cart/getItemByProductId",
      isAuthenticatedss: "cart/isAuthenticated"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    productClasses() {
      if (global.config.filterProductClasses && this.product.productClasses) {
        return this.product.productClasses.filter(
          p =>
            global.config.filterProductClasses.includes(p.productClassId) &&
            p.productClassTypeId == 2 &&
            p.productClassGroupId != 1
        );
      } else {
        return this.product.productClasses.filter(
          p => p.productClassTypeId == 2 && p.productClassGroupId != 1
        );
      }
    },
    item() {
      return null;
    }
  }
};
</script>
